import React from 'react'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import './all.css'
import useSiteMetadata from './SiteMetadata'

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content="https://www.dendanovesvobody.cz/og-image-facebook-v3.png" />
        <meta property="og:url" content="/" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://www.dendanovesvobody.cz/og-image-twitter-v3.png" />
        <meta name="twitter:image:alt" content={description} />
        <meta name="twitter:site" content="@libinstcz" />
        <meta name="twitter:creator" content="@libinstcz" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />

        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#201d1d" />
        <meta name="msapplication-TileColor" content="#201d1d" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <div>{children}</div>
      <Footer />
    </>
  )
}

export default TemplateWrapper
