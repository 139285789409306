import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import Marked from 'react-markdown'
import styled from '@emotion/styled';

const Contact = styled("div")`
  h3 {
    margin-top: 1em;
    font-size: 1.1rem;
  }

  p {
    margin-bottom: 0;
  }
`;

export default () => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
        frontmatter {
          contact
        }
      }
    }
  `)

  return (
    <Contact>
      <Marked source={data.markdownRemark.frontmatter.contact} />
    </Contact>
  );
};
